import { Modal } from '@dale/react-bacan-ui';
import { LegalDocumentContainerStyled, modalStyles } from './styles';
import { LegalDocumentProps } from './interfaces';

export const LegalDocument = ({
  text,
  isVisible,
  onClose,
}: LegalDocumentProps) => {
  return (
    <Modal
      isVisible={isVisible}
      onCloseModal={onClose}
      containerProps={{
        style: modalStyles,
      }}
    >
      <LegalDocumentContainerStyled
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Modal>
  );
};
