import ENV from './constants/env';

export const environment_var = {
  reCaptchaKey: ENV.REACT_APP_PSE_RECAPTCHA_SITE_KEY,
}

const environments = {
  stg: {
    httpLink: 'https://stg.daleapps.com/api/graphql',
  },
  dev: {
    httpLink: 'https://dev.daleapps.com/api/graphql',
  },
  prod: {
    httpLink: 'https://prod.daleapps.com/api/graphql',
  },
};

const environment = 'qa';

const selectedEnvironmentKey = environment.replace('{{', '').replace('}}', '');

export const selectedEnvironment =
  environments[selectedEnvironmentKey as keyof typeof environments];
